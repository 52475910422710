import React, { useState, useMemo } from 'react'
import { ConfigProvider, Dropdown, Input, Button } from 'antd'
import { DownOutlined, SearchOutlined } from '@ant-design/icons'
import { Company } from '@/utils/types'

interface CompanySwitcherProps {
  companies: Company[]
  onCompanyChange?: (company: Company) => void
}

const color = '#1A3C68'
const lightColor = '#255CA4'
const theme = {
  token: {
    colorPrimary: color,
  },
  components: {
    Button: {
      colorPrimary: color,
      colorPrimaryHover: lightColor,
    },
    Dropdown: {
      controlItemBgActive: color,
      controlItemBgActiveHover: lightColor,
    },
  },
}

export default function CompanySwitcher({ companies = [], onCompanyChange }: CompanySwitcherProps) {
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(companies[0] || null)
  const [searchValue, setSearchValue] = useState('')

  const filteredCompanies = useMemo(
    () => companies.filter((company) => company.name.toLowerCase().includes(searchValue.toLowerCase()) && !company.disabled),
    [companies, searchValue]
  )

  const handleCompanySelect = (company: Company) => {
    setSelectedCompany(company)
    if (onCompanyChange) {
      onCompanyChange(company)
    }
  }

  const menuItems = useMemo(() => {
    const items = []

    if (companies.length > 5) {
      items.push({
        key: 'search',
        label: (
          <Input
            placeholder="医院を検索"
            prefix={<SearchOutlined />}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onClick={(e) => e.stopPropagation()}
          />
        ),
      })
    }

    items.push(
      ...filteredCompanies.map((company) => ({
        key: company.id,
        label: (
          <div title={company.name} style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {company.name}
          </div>
        ),
        onClick: () => handleCompanySelect(company),
        disabled: company.disabled,
      }))
    )

    return items
  }, [filteredCompanies, searchValue, companies.length])

  return (
    <ConfigProvider theme={theme}>
      <div style={{ width: '100%' }}>
        <Dropdown menu={{ items: menuItems }} trigger={['click']}>
          <Button
            type="primary"
            style={{
              width: '100%',
              textAlign: 'left',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            医院を選択
            <DownOutlined />
          </Button>
        </Dropdown>
      </div>
    </ConfigProvider>
  )
}
