'use client'

import { Company } from '@/utils/types'
import { Dispatch, SetStateAction, createContext, useContext, useState } from 'react'

type McUserValuesType = {
  sub: string
  familyName: string
  givenName: string
  profileImage: string
  reviewerType: string
  companyId: string
}

interface McUserContextType {
  mcUser: McUserValuesType | null
  setMcUser: Dispatch<SetStateAction<McUserValuesType | null>>

  mcUserCompanies: Company[] | null
  setMcUserCompanies: Dispatch<SetStateAction<Company[] | null>>
}

interface McUserProviderProps {
  children: React.ReactNode
}

// Contextの作成
const McUserContext = createContext<McUserContextType>({
  mcUser: null,
  setMcUser: () => {},

  mcUserCompanies: [],
  setMcUserCompanies: () => {},
})

// Providerコンポーネントの作成
export const McUserProvider = ({ children }: McUserProviderProps) => {
  // mcUser
  // ユーザ情報を格納
  const [mcUser, setMcUser] = useState<McUserValuesType | null>(null)

  // mcUserCompanies
  // ユーザが所属している医院
  const [mcUserCompanies, setMcUserCompanies] = useState<Company[] | null>(null)

  return <McUserContext.Provider value={{ mcUser, setMcUser, mcUserCompanies, setMcUserCompanies }}>{children}</McUserContext.Provider>
}

// コンテキストを使用するためのカスタムフック
export const useMcUser = () => useContext(McUserContext)
