'use client'

import config from '@/amplifyconfiguration.json'
import amplifyJaVocabularies from '@/utils/amplify-ja-vocabularies'
import { translations } from '@aws-amplify/ui'
import { Amplify } from 'aws-amplify'
import { generateClient } from 'aws-amplify/api'
import { I18n } from 'aws-amplify/utils'

Amplify.configure(config, { ssr: true })

// Amplify言語設定
I18n.putVocabularies(translations)
I18n.setLanguage('ja')
I18n.putVocabularies(amplifyJaVocabularies)

export const client = generateClient()

export default function ConfigureAmplifyClientSide() {
  return null
}
