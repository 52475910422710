import(/* webpackMode: "eager" */ "/codebuild/output/src2700864273/src/mc-reviewer-dashboard/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Noto_Sans_JP\",\"arguments\":[{\"weight\":[\"400\",\"700\"],\"style\":\"normal\",\"subsets\":[\"latin\"]}],\"variableName\":\"noto\"}");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2700864273/src/mc-reviewer-dashboard/node_modules/@aws-amplify/ui-react/dist/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2700864273/src/mc-reviewer-dashboard/src/app/ant.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2700864273/src/mc-reviewer-dashboard/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2700864273/src/mc-reviewer-dashboard/src/components/auth/AuthProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2700864273/src/mc-reviewer-dashboard/src/components/auth/ConfigureAmplifyClientSide.ts");
;
import(/* webpackMode: "eager", webpackExports: ["McUserProvider"] */ "/codebuild/output/src2700864273/src/mc-reviewer-dashboard/src/contexts/McUserContext.tsx");
