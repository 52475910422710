'use client'

import { View } from '@aws-amplify/ui-react'
import { Authenticator, useTheme } from '@aws-amplify/ui-react'
import useAuthRedirect from '@/components/auth/useAuthRedirect'
import '@aws-amplify/ui-react/styles.css'

const components = {
  Header() {
    const { tokens } = useTheme()

    return (
      <View style={styles.header} textAlign="center" padding={tokens.space.large}>
        <img style={styles.logo} alt="Medicloud Logo" src="/images/logo/medicloud-logo-text-white.svg" />
        <div style={styles.headerTitle}>評価者ダッシュボード</div>
      </View>
    )
  },
}

export default function AuthenticatorClient() {
  // サインイン済みならトップにリダイレクト
  useAuthRedirect({
    authhStatus: 'authenticated',
    redirectPath: '/',
  })

  return <Authenticator hideSignUp={true} components={components} />
}

const styles = {
  header: {
    marginBottom: 30,
  },
  headerTitle: {
    fontSize: '14px',
    marginTop: 10,
  },
  logo: {
    maxWidth: '240px',
    height: 'auto',
  },
}
