'use client'

import React, { useEffect, useMemo, useState } from 'react'

// antd
import {
  ApartmentOutlined,
  DollarOutlined,
  FileTextOutlined,
  FolderOutlined,
  HeartOutlined,
  HomeOutlined,
  IdcardOutlined,
  LineChartOutlined,
  LogoutOutlined,
  RadarChartOutlined,
  RiseOutlined,
  ScheduleOutlined,
  SettingOutlined,
  SmileOutlined,
  StarOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons'
import type { MenuProps } from 'antd'
import { Avatar, ConfigProvider, Dropdown, Layout, Menu, message } from 'antd'
import Link from 'next/link'
import { useParams, usePathname, useRouter, useSelectedLayoutSegments } from 'next/navigation'
import styles from './navigation.module.css'
const { Content, Sider } = Layout

// dayjs (alternative momentjs)
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
dayjs.locale('ja-jp')

// 言語設定系
import jaJP from 'antd/lib/locale/ja_JP'
import 'moment/locale/ja'

// Amplify
import AuthenticatorClient from '@/components/auth/AuthenticatorClient'
import useAuthRedirect from '@/components/auth/useAuthRedirect'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { fetchUserAttributes } from 'aws-amplify/auth'

// カスタム
import LoadingOverlay from '@/components/LoadingOverlay/LoadingOverlay'
import { useMcUser } from '@/contexts/McUserContext'
import mcUtils from '@/utils/mc-utils'
import { McDevBgColor } from './constants'
import CompanySwitcher from '@/components/CompanySwitcher'
import { Company } from '@/utils/types'
import api from '@/utils/api'

/**
 * メニュー用のDivider
 */
function getMenuDivider() {
  return {
    key: 'divider' + Math.floor(Math.random() * 100000),
    label: <div className={styles.menuDivider} />,
    disabled: true, // Dividerは選択不可にする
    style: { padding: 0 }, // デフォルトのパディングを削除
  }
}

// メニューリスト
const sidebarItems = [
  // 評価管理
  {
    key: 'reviews',
    icon: <LineChartOutlined />,
    label: '評価管理',
  },
  {
    key: 'masters',
    icon: <FolderOutlined />,
    label: 'マスター管理',
    children: [
      {
        key: 'masters/competency',
        icon: <HeartOutlined />,
        label: 'コンピテンシー',
        insertEvaluationCycleId: true, // masters/のうしろにevaluationCycleIdを追加する（パラメータがある場合）
      },
      {
        key: 'masters/skill-sheet',
        icon: <RadarChartOutlined />,
        label: 'スキルシート',
        insertEvaluationCycleId: true, // masters/のうしろにevaluationCycleIdを追加する（パラメータがある場合）
      },
      {
        key: 'masters/evaluation-sheet',
        icon: <FileTextOutlined />,
        label: '評価シート',
        insertEvaluationCycleId: true, // masters/のうしろにevaluationCycleIdを追加する（パラメータがある場合）
      },
      {
        key: 'masters/salary',
        icon: <DollarOutlined />,
        label: '給与テーブル',
        insertEvaluationCycleId: true, // masters/のうしろにevaluationCycleIdを追加する（パラメータがある場合）
      },
    ],
  },
  // {
  //   key: 'messages',
  //   icon: <MailOutlined />,
  //   label: 'メッセージ管理',
  // },

  getMenuDivider(),

  {
    key: 'employees',
    icon: <IdcardOutlined />,
    label: '従業員管理',
  },

  // ダッシュボード設定
  {
    key: 'reviewers',
    icon: <TeamOutlined />,
    label: '評価者管理',
  },
  {
    key: 'company',
    icon: <HomeOutlined />,
    label: '医院管理',
    children: [
      {
        key: 'company/setting',
        icon: <SettingOutlined />,
        label: '基本設定',
      },
      {
        key: 'company/departments',
        icon: <ApartmentOutlined />,
        label: '部署',
      },
      {
        key: 'company/positions',
        icon: <StarOutlined />,
        label: '役職',
      },
      {
        key: 'company/roles',
        icon: <SmileOutlined />,
        label: '職種',
      },
      {
        key: 'company/ranks',
        icon: <RiseOutlined />,
        label: 'ランク',
      },
      {
        key: 'company/evaluation-period',
        icon: <ScheduleOutlined />,
        label: '評価期間',
      },
    ],
  },

  getMenuDivider(),

  // ヘルプなど
  // {
  //   key: 'help',
  //   icon: <InfoCircleOutlined />,
  //   label: 'ヘルプ',
  // },
  // {
  //   key: 'support',
  //   icon: <CommentOutlined />,
  //   label: 'お問い合わせ',
  // },
  // {
  //   key: 'announcements',
  //   icon: <CheckCircleOutlined />,
  //   label: 'お知らせ',
  // },

  // getMenuDivider(),
]

// 管理者用メニューリスト
const sidebarAdminItems = [
  // 医院・評価者管理
  {
    key: 'admin-companies',
    icon: <HomeOutlined />,
    label: '全医院リスト',
  },

  {
    key: 'admin-reviewers',
    icon: <TeamOutlined />,
    label: '全評価者リスト',
  },

  // テンプレ管理
  // {
  //   key: 'masters',
  //   icon: <FolderOutlined />,
  //   label: 'テンプレ管理',
  //   children: [
  //     {
  //       key: 'masters/competency',
  //       icon: <HeartOutlined />,
  //       label: 'コンピテンシー',
  //       insertEvaluationCycleId: true, // masters/のうしろにevaluationCycleIdを追加する（パラメータがある場合）
  //     },
  //     {
  //       key: 'masters/skill-sheet',
  //       icon: <RadarChartOutlined />,
  //       label: 'スキルシート',
  //       insertEvaluationCycleId: true, // masters/のうしろにevaluationCycleIdを追加する（パラメータがある場合）
  //     },
  //     {
  //       key: 'masters/evaluation-sheet',
  //       icon: <FileTextOutlined />,
  //       label: '評価シート',
  //       insertEvaluationCycleId: true, // masters/のうしろにevaluationCycleIdを追加する（パラメータがある場合）
  //     },
  //     {
  //       key: 'masters/salary',
  //       icon: <DollarOutlined />,
  //       label: '給与テーブル',
  //       insertEvaluationCycleId: true, // masters/のうしろにevaluationCycleIdを追加する（パラメータがある場合）
  //     },
  //   ],
  // },
]

export default function Navigation({ children }: { children: React.ReactNode }) {
  const params = useParams()
  const evaluationCycleId = params.evaluationCycleId

  // メッセージ用
  const [messageApi, contextHolder] = message.useMessage()

  // 未ログイン状態だったら/signinにリダイレクト
  useAuthRedirect({
    authhStatus: 'unauthenticated',
    redirectPath: '/signin',
  })

  // ユーザ情報格納
  const { mcUser, setMcUser, mcUserCompanies, setMcUserCompanies } = useMcUser()

  // サインアウトボタン
  const { signOut, authStatus } = useAuthenticator()
  const { route } = useAuthenticator((context) => [context.route])
  const pathname = usePathname()
  const router = useRouter()

  // ローディング
  const [loading, setLoading] = useState(false)

  /**
   * ユーザが所属している医院を取得
   */
  const getUserCompanies = async () => {
    try {
      const userCompanies: Company[] = await api.get<Company[]>('/user-info/companies')
      console.log('userCompanies', userCompanies)

      // mcUserContextのmcUserCompaniesにも設定
      setMcUserCompanies(userCompanies)
    } catch (e) {
      console.log('poistion error', e)
    }
  }

  /**
   * 初期データを取得
   */
  const getInitialData = async () => {
    await Promise.all([getUserCompanies()])
  }

  /**
   * ユーザの所属医院を更新
   */
  const updateUserCompany = async (company: Company) => {
    // ローディング中に
    setLoading(true)

    try {
      const result = await api.post('/user-info/company', { companyId: company.id })
      console.log('updateUserCompany', result)

      messageApi.success(`医院を「${company.name}」に変更しました！リロードします。`)

      // セッションを強制更新
      await mcUtils.forceRefreshAuthSession()

      // ブラウザをトップページにリロードして移動
      window.location.href = '/'
    } catch (e) {
      console.log('updateUserCompany error', e)

      messageApi.error('医院の変更に失敗しました。')

      // ローディング終了
      setLoading(false)
    }
  }

  // 選択している医院名
  const selectedCompanyName = useMemo(() => {
    console.log('[selectedCompanyName] start', mcUserCompanies, mcUser)

    // 所属している医院リストとユーザ情報が取得されているか
    if (mcUserCompanies && mcUser) {
      const company = mcUserCompanies.find((c) => c.id === mcUser.companyId)

      console.log('[getCompanyName] company', company)

      return company?.name
    }

    return null
  }, [mcUserCompanies, mcUser])

  // ユーザが選択している医院以外の所属している医院
  const otherCompanies = useMemo(() => {
    return mcUserCompanies?.filter((c) => c.id !== mcUser?.companyId)
  }, [mcUserCompanies, mcUser])

  // 認証済みユーザーであればホームにリダイレクト
  useEffect(() => {
    console.log('authStatus', authStatus)

    // すでにログイン済みの場合はホームにリダイレクト
    if (pathname === '/signin' && authStatus === 'authenticated') {
      router.push('/')
    }

    // ログイン済みの場合はユーザ情報を取得
    if (authStatus === 'authenticated') {
      getMcUser()
    }
  }, [router, authStatus])

  // 初期データを取得
  useEffect(() => {
    getInitialData()
  }, [])

  // たためるメニュー(childrenの中の項目)を集める。これをsegment生成時に利用する
  // たためるメニューとそれ以外の普通のメニューとでsegmentの方式が違うので。
  const childrenKeys: string[] = []
  for (const item of sidebarItems) {
    if ('children' in item && item.children) {
      item.children.forEach((child) => {
        childrenKeys.push(child.key)
      })
    }
  }

  // サイドバーメニューの強調表示する箇所を決めるためのセグメント生成
  const segments = useSelectedLayoutSegments()
  // /masters/evaluationCycleId/XXXX の場合は、/masters/XXXXにする
  if (segments.length > 1 && segments[0] === 'masters') {
    // 2番目がevaluationCycleIdなので、2番目の文字を削除した配列にする
    segments.splice(1, 1)
  }
  // segmentsの配列の文字列を/で連結
  let segment = segments.join('/')
  // たためるメニューのkeyがchildrenKeysに含まれているかどうか
  if (!childrenKeys.includes(segment)) {
    // たためるメニューじゃないので、サイドバーメニューのセグメントを取得
    segment = segments?.length > 0 ? segments[0] : ''
  }
  // たためるメニュー内の項目の場合は、メニューを開くようにする
  const defaultOpenKeys = segments.length > 1 ? [segments[0]] : []
  // console.log(`segments: ${segments}, segment: ${segment}, defaultOpenKeys: ${defaultOpenKeys}`)

  /**
   * ユーザ情報を取得する
   */
  async function getMcUser() {
    // ユーザ情報取得
    const userAttributes = await fetchUserAttributes()
    const familyName = userAttributes.family_name
    const givenName = userAttributes.given_name
    const profileImage = userAttributes['custom:profile_image']
    const sub = userAttributes.sub
    const reviewerType = userAttributes['custom:reviewer_type']
    const companyId = userAttributes['custom:company']
    console.log('userAttributes', userAttributes, `sub: ${sub}, profileImage: ${profileImage}, reviewerType: ${reviewerType}`)

    // ユーザ情報を格納
    setMcUser({
      familyName: familyName || '',
      givenName: givenName || '',
      profileImage: profileImage || '',
      sub: sub || '',
      reviewerType: reviewerType || '',
      companyId: companyId || '',
    })

    console.log(`USER familtyName: ${familyName}, givenName: ${givenName}, profileImage: ${profileImage}, reviewerType: ${reviewerType}`)
  }

  /**
   * 医院を変更する
   */
  const handleCompanyChange = async (company: Company) => {
    console.log('handleCompanyChange', company)

    await updateUserCompany(company)
  }

  // メニューのアイテムはリンクに変換される
  function createMenuItem(item: any) {
    if (item && item.label) {
      if (item.children) {
        return {
          key: item.key,
          icon: item.icon,
          label: item.label,
          children: item.children.map((child: any) => createMenuItem(child)),
        }
      } else {
        // console.log('###', item.insertEvaluationCycleId, evaluationCycleId)
        let key = item.key
        if (item.insertEvaluationCycleId && evaluationCycleId) {
          // masters/のうしろにevaluationCycleIdを追加する
          const segments = key.split('/')
          // 2番目にevaluationCycleIdを追加する
          segments.splice(1, 0, evaluationCycleId)
          key = segments.join('/')
        }
        return {
          key: item.key,
          icon: item.icon,
          label: item.key?.includes('divider') ? item.label : <Link href={`/${key}`}>{item.label}</Link>,
          disabled: item.key?.includes('divider'),
          style: { pointerEvents: item.key?.includes('divider') ? 'none' : 'auto' },
        }
      }
    } else {
      return item
    }
  }

  // ユーザメニューのサインアウトを実行する
  const handleUserMenuClick: MenuProps['onClick'] = async (e) => {
    // console.log('click', e)
    if (e.key === 'signout') {
      signOut()
    }
  }

  const userMenuItems: MenuProps['items'] = [
    {
      key: 'user-profile',
      // label: <Link href="/user-profile">ユーザー設定</Link>,
      label: <Link href={`/reviewers/${mcUser?.sub}`}>ユーザー設定</Link>,
      icon: <UserOutlined />,
    },
    {
      type: 'divider',
    },
    {
      key: 'signout',
      label: 'ログアウト',
      icon: <LogoutOutlined />,
    },
  ]

  // console.log('route', route)

  // 開発環境だとサイドバーがゴールドになる
  const siderBgColor = process.env.NEXT_PUBLIC_MC_ENV === 'dev' ? { background: McDevBgColor } : {}
  // デバッグ用: 常に本番カラー
  //const siderBgColor = {}

  if (route === 'transition' || route === 'signOut') {
    return <LoadingOverlay show={true} />
  } else if (authStatus === 'authenticated') {
    return (
      <>
        {contextHolder}
        {loading && <LoadingOverlay show={true} />}
        <Layout style={{ minHeight: '100vh' }}>
          <Sider
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={(broken) => {
              // console.log(broken)
            }}
            onCollapse={(collapsed, type) => {
              // console.log(collapsed, type)
            }}
            style={siderBgColor}
          >
            <Link href="/">
              {/* <div
                className={styles.mcLogo}
                style={{
                  backgroundImage: `url(/images/logo/medicloud-${mcUser?.reviewerType === 'admin' ? 'admin-logo' : 'logo'}-text-white.svg)`,
                  height: mcUser?.reviewerType === 'admin' ? '38px' : '17px',
                }}
              /> */}
              <div
                className={styles.mcLogo}
                style={{
                  backgroundImage: `url(/images/logo/medicloud-logo-text-white.svg)`,
                  height: '17px',
                }}
              />
            </Link>

            <Menu
              theme="dark"
              mode="inline"
              selectedKeys={[segment || '']}
              defaultOpenKeys={defaultOpenKeys}
              items={sidebarItems.map(createMenuItem)}
              style={siderBgColor}
            />

            {/* ユーザ画像 + 名前 */}
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '25px' }}>
              <Dropdown menu={{ items: userMenuItems, onClick: handleUserMenuClick }}>
                <div style={{ float: 'right', cursor: 'pointer' }}>
                  <Avatar shape="square" size="large" src={mcUtils.getReviewerProfileImageUrl(mcUser?.profileImage)} />
                  <span className={styles.userName}>
                    {mcUser?.familyName} {mcUser?.givenName}
                  </span>
                </div>
              </Dropdown>
            </div>

            {/* ユーザがadminの場合は医院を変更できる */}
            {mcUser?.reviewerType === 'admin' && (
              <>
                <div className={styles.menuDivider} style={{ marginLeft: '25px', marginRight: '25px', marginTop: '20px', marginBottom: '20px' }} />

                {selectedCompanyName && (
                  <>
                    <div style={{ marginLeft: '25px', marginRight: '25px', marginBottom: '3px', fontSize: '10px' }}>現在の医院</div>
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '25px', marginRight: '25px', marginBottom: '10px' }}>
                      {selectedCompanyName}
                    </div>
                  </>
                )}

                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '25px', marginBottom: '20px', marginRight: '25px' }}>
                  <CompanySwitcher companies={otherCompanies || []} onCompanyChange={handleCompanyChange} />
                </div>

                <div className={styles.menuDivider} style={{ marginLeft: '25px', marginRight: '25px', marginTop: '20px', marginBottom: '10px' }} />

                <div style={{ marginLeft: '25px', marginRight: '25px', marginTop: '20px', marginBottom: '3px', fontSize: '10px' }}>
                  管理者メニュー
                </div>

                <Menu
                  theme="dark"
                  mode="inline"
                  selectedKeys={[segment || '']}
                  defaultOpenKeys={defaultOpenKeys}
                  items={sidebarAdminItems.map(createMenuItem)}
                  style={siderBgColor}
                />

                <div style={{ marginBottom: '40px' }} />
              </>
            )}

            <div className={styles.siderFooter}>Medicloud, Inc. ©{new Date().getFullYear()}</div>
          </Sider>
          <Layout>
            <ConfigProvider locale={jaJP}>
              <Content className={styles.container}>{children}</Content>
            </ConfigProvider>
          </Layout>
        </Layout>
      </>
    )
  } else if (authStatus === 'unauthenticated') {
    return (
      <Layout className={styles.layoutContainer}>
        <AuthenticatorClient />
      </Layout>
    )
  } else {
    // それ以外の認証ステータス(configuring)はローディング表示
    return <LoadingOverlay show={true} />
  }
}
