'use client'

import React from 'react'

import { McBgColor, McColor } from './constants'

// Amplify
import { ColorMode, ThemeProvider, defaultDarkModeOverride } from '@aws-amplify/ui-react'

// Ant Design
import { AntdRegistry } from '@ant-design/nextjs-registry'
import { ConfigProvider, theme } from 'antd'

// ページ遷移中のプログレスバー
import NextTopLoader from 'nextjs-toploader'

// ナビゲーション
import Navigation from '@/app/navigation'

export default function Ant({ children }: { children: React.ReactNode }) {
  // Ant designテーマ (システムテーマを継承)
  // const isDarkTheme = window.matchMedia('(prefers-color-scheme: dark)').matches
  const isDarkTheme = false // 強制的にテーマを設定

  // ダークテーマとライトテーマを切り替える
  const antdTheme = isDarkTheme
    ? {
        algorithm: theme.darkAlgorithm,
        token: {
          colorPrimary: McColor,
          colorBgBase: McBgColor, // 画面の背景色
          borderRadius: 4,
          // pageContainer: {
          //   paddingBlockPageContainerContent: 0,
          //   paddingInlinePageContainerContent: 0,
          //   marginBlockPageContainerContent: 0,
          //   marginInlinePageContainerContent: 0,
          // },
        },
        components: {},
      }
    : {
        algorithm: theme.defaultAlgorithm,
        token: {
          colorPrimary: McColor,
          borderRadius: 4,
          // pageContainer: {
          //   paddingBlockPageContainerContent: 0,
          //   paddingInlinePageContainerContent: 0,
          //   marginBlockPageContainerContent: 0,
          //   marginInlinePageContainerContent: 0,
          // },
        },
        components: {},
      }

  // レイアウトのトリガー(スマホ表示のときの左上に表示されるタブ)を35pxに設定
  antdTheme.components = {
    Layout: {
      zeroTriggerHeight: 35,
      zeroTriggerWidth: 35,
      headerHeight: 10,
    },
    Menu: {
      darkSubMenuItemBg: 'rgba(255, 255, 255, 0.1)',
      darkItemSelectedBg: McColor,
      itemHeight: 35,
    },
  }

  // Amplify テーマ
  // const colorMode: ColorMode = 'light' // system, dark or light
  const colorMode: ColorMode = 'system' // system, dark or light
  const amplifyTheme = {
    name: 'mcTheme',
    overrides: [defaultDarkModeOverride],
    tokens: {
      colors: {
        brand: {
          primary: { value: McColor }, // プライマリカラーを設定
        },
      },
    },
  }

  return (
    // AmplifyのテーマをThemeProviderで設定
    <ThemeProvider theme={amplifyTheme} colorMode={colorMode}>
      <AntdRegistry>
        {/* Ant DesignのConfigProviderでAnt Designのテーマ設定を設定 */}
        <ConfigProvider theme={antdTheme}>
          {/* ナビゲーションは内部でログインページには表示しないようにしている */}
          <Navigation>{children}</Navigation>
          <NextTopLoader />
        </ConfigProvider>
      </AntdRegistry>
    </ThemeProvider>
  )
}
