export default {
  ja: {
    'Enter your email': 'メールアドレスを入力してください',
    'Incorrect username or password.': 'メールアドレスまたはパスワードが間違っています',
    'User does not exist.': 'ユーザーが存在しません',
    'Username/client id combination not found.': '指定のメールアドレスのユーザーが見つかりません',
    'Family Name': '名字 (例: 山田)',
    'Given Name': '名前 (例: 太郎)',
    'Enter your Family Name': '名字を入力してください',
    'Enter your Given Name': '名前を入力してください',
    'Change Password': 'パスワードを変更してください',
    Verifying: '確認中',
    Code: 'コード',
  },
}
